import "./style.css"
import "./icons"
import { Elm } from "./elm/Main.elm"

import "webcomponent-qr-code"
import "../ffi/Bark"

import { WebLn } from "./webln"


const webln = new WebLn()


const locationUrl = new URL(window.location.href)

const elm = Elm.Main.init({
  flags: {
    apiUrl: process.env.API_URL,
    clientToken: locationUrl.searchParams.get("clientToken"),
    embedded: locationUrl.searchParams.get("embedded") !== null,
    language: navigator.language,
    development: process.env.NODE_ENV === "development",
    origin: locationUrl.origin,
  },
})

elm.ports.portSend.subscribe(subscribeHandler)

const toElm = elm.ports.portReceive.send

function subscribeHandler(action: any): void {
  switch (action.tag) {
    case "bolt11": {
      void webln.bolt11(action.bolt11)
      break
    }
    case "webln-init": {
      void webln.init()
      break
    }
    case "copy":
      void navigator.clipboard.writeText(action.text).then(() => toElm({ tag: "copied" }))
      break

    case "toast": {
      setTimeout(() => {
        toElm({ tag: "toast" })
      }, 3000);
      break
    }

    default:
      return
  }

  return
}

// Websockets

elm.ports.wsPortSend.subscribe(websocketHandler)

let ws: WebSocket | undefined

async function websocketHandler(action: any) {
  console.log(action);

  switch (action.tag) {
    case "connect": {
      if (ws) {
        ws.close()
      }
      ws = new WebSocket(process.env.WS_URL as string + window.location.search)

      // Connection opened
      ws.addEventListener("open", (event) => {
        ws?.send("Hello Server!");
      });
      ws.onopen = () => wsToElm({ tag: "open" })
      ws.onclose = () => wsToElm({ tag: "close" })
      ws.onerror = () => wsToElm({ tag: "error" })
      ws.onmessage = (event) => wsToElm({ tag: "message", data: event.data })
      break
    }

    case "message": {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify(action.data))
      }
      break
    }

    default: {
      if (ws && ws.readyState === WebSocket.OPEN) {
        // TODO: remove this
        ws.send(JSON.stringify(action))
      }

    }

  }

  return
}

const wsToElm = elm.ports.wsPortReceive.send



// Handle dark mode
const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
handleDarkMode(prefersDarkMode)
window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (event) => {
  const prefersDarkMode = event.matches;
  handleDarkMode(prefersDarkMode)
});



function handleDarkMode(prefersDarkMode: boolean) {
  // Disable dark mode for now for embedded apps
  const isEmbedded = locationUrl.searchParams.get("embedded") !== null
  if (isEmbedded) {
    return
  }

  // Add or remove dark class
  // 
  // To have more control over dark mode, 
  // we have our TailwindCSS config set to darkMode: "class"
  // to the root node aka body
  if (prefersDarkMode) {
    document.body.classList.add("dark")
  } else {
    document.body.classList.remove("dark")
  }
}