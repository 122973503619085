import { WebLNProvider, requestProvider } from "webln";






export class WebLn {
  webln: WebLNProvider | null = null
  invoice: string | null = null

  constructor() {

  }

  async init() {
    try {
      this.webln = await requestProvider();
      console.log("Webln initialized")

      // run sendPayment if invoice exist
      // this can happen when a wallet is still unconnected or locked
      if (this.invoice) {
        this.bolt11(this.invoice)
      }
    } catch (err) {
      console.log("Error initializing webln:")
      console.log(err)
    }
  }

  /**
   * foo
   */
  public bolt11(bolt11: string) {
    if (this.webln) {
      this.webln.sendPayment(bolt11)
    } else {
      // write to cach
      this.invoice = bolt11
    }
  }
}

