import circleNotch from "@iconify/icons-fa6-solid/circle-notch";
import check from "@iconify/icons-fa6-solid/check";
import xmark from "@iconify/icons-fa6-solid/xmark";
import circleExclamation from "@iconify/icons-fa6-solid/circle-exclamation";

const icons = {
  "circle-notch": circleNotch,
  "check": check,
  "xmark": xmark,
  "circleExclamation": circleExclamation,
};

class IconComponent extends HTMLElement {
  constructor() {
    super();
    // this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ["icon"];
  }

  attributeChangedCallback(name: any, oldValue: any, newValue: any) {
    if (oldValue !== newValue) {
      this.render();
    }
  }

  render() {
    const icon = this.getAttribute("icon");
    if (!icon) {
      console.warn("Icon attribute is required");
      return;
    }

    // @ts-ignore
    const iconData = icons[icon];
    if (iconData) {
      // const svg = renderIcon(iconData as Icon);
      this.innerHTML = `
        <style>
          svg {
            width: 24px;
            height: 24px;
          }
        </style>
        <svg class="${this.className}" viewBox="0 0 ${iconData.width} ${iconData.height}" >
        ${iconData.body}
        </svg>
      `;
    } else {
      console.error("Icon not found ", icon);
      this.innerHTML = "";
    }
  }
}

customElements.define("icon-component", IconComponent);
