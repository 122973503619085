"use strict";
/* tslint:disable:max-classes-per-file */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalError = exports.InvalidDataError = exports.RoutingError = exports.UnsupportedMethodError = exports.ConnectionError = exports.RejectionError = exports.MissingProviderError = void 0;
/**
 * Workaround for custom errors when compiling typescript targeting 'ES5'.
 * see: https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
 * @param {CustomError} error
 * @param newTarget the value of `new.target`
 * @param {Function} errorType
 */
function fixError(error, newTarget, errorType) {
    Object.setPrototypeOf(error, errorType.prototype);
    // when an error constructor is invoked with the `new` operator
    if (newTarget === errorType) {
        error.name = newTarget.name;
        // exclude the constructor call of the error type from the stack trace.
        if (Error.captureStackTrace) {
            Error.captureStackTrace(error, errorType);
        }
        else {
            var stack = new Error(error.message).stack;
            if (stack) {
                error.stack = fixStack(stack, "new ".concat(newTarget.name));
            }
        }
    }
}
function fixStack(stack, functionName) {
    if (!stack)
        return stack;
    if (!functionName)
        return stack;
    // exclude lines starts with:  "  at functionName "
    var exclusion = new RegExp("\\s+at\\s".concat(functionName, "\\s"));
    var lines = stack.split("\n");
    var resultLines = lines.filter(function (line) { return !line.match(exclusion); });
    return resultLines.join("\n");
}
/// CUSTOM ERRORS ///
// When no WebLN provider is available
var MissingProviderError = /** @class */ (function (_super) {
    __extends(MissingProviderError, _super);
    function MissingProviderError(message) {
        var _newTarget = this.constructor;
        var _this = _super.call(this, message) || this;
        fixError(_this, _newTarget, MissingProviderError);
        return _this;
    }
    return MissingProviderError;
}(Error));
exports.MissingProviderError = MissingProviderError;
// When the user rejects a request
var RejectionError = /** @class */ (function (_super) {
    __extends(RejectionError, _super);
    function RejectionError(message) {
        var _newTarget = this.constructor;
        var _this = _super.call(this, message) || this;
        fixError(_this, _newTarget, RejectionError);
        return _this;
    }
    return RejectionError;
}(Error));
exports.RejectionError = RejectionError;
// When the node can't be connected to (i.e. the app did nothing wrong)
var ConnectionError = /** @class */ (function (_super) {
    __extends(ConnectionError, _super);
    function ConnectionError(message) {
        var _newTarget = this.constructor;
        var _this = _super.call(this, message) || this;
        fixError(_this, _newTarget, ConnectionError);
        return _this;
    }
    return ConnectionError;
}(Error));
exports.ConnectionError = ConnectionError;
// The WebLN provider doesn't support this method
var UnsupportedMethodError = /** @class */ (function (_super) {
    __extends(UnsupportedMethodError, _super);
    function UnsupportedMethodError(message) {
        var _newTarget = this.constructor;
        var _this = _super.call(this, message) || this;
        fixError(_this, _newTarget, UnsupportedMethodError);
        return _this;
    }
    return UnsupportedMethodError;
}(Error));
exports.UnsupportedMethodError = UnsupportedMethodError;
// The desired node couldn't be routed to
var RoutingError = /** @class */ (function (_super) {
    __extends(RoutingError, _super);
    function RoutingError(message) {
        var _newTarget = this.constructor;
        var _this = _super.call(this, message) || this;
        fixError(_this, _newTarget, RoutingError);
        return _this;
    }
    return RoutingError;
}(Error));
exports.RoutingError = RoutingError;
// An argument passed was somehow invalid (e.g. malformed invoice)
var InvalidDataError = /** @class */ (function (_super) {
    __extends(InvalidDataError, _super);
    function InvalidDataError(message) {
        var _newTarget = this.constructor;
        var _this = _super.call(this, message) || this;
        fixError(_this, _newTarget, InvalidDataError);
        return _this;
    }
    return InvalidDataError;
}(Error));
exports.InvalidDataError = InvalidDataError;
// Something broke in the WebLN provider internally, nothing to do with the app
var InternalError = /** @class */ (function (_super) {
    __extends(InternalError, _super);
    function InternalError(message) {
        var _newTarget = this.constructor;
        var _this = _super.call(this, message) || this;
        fixError(_this, _newTarget, InternalError);
        return _this;
    }
    return InternalError;
}(Error));
exports.InternalError = InternalError;
