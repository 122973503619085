import { marked } from "marked";
import DOMPurify from "dompurify";

class ElmBark extends HTMLElement {
  private _content = "";

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  static get observedAttributes() {
    return ["content"];
  }

  get content(): string {
    return this._content;
  }

  set content(value: string) {
    this._content = value;
    this.render();
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === "content") {
      this._content = newValue;
    }
  }

  connectedCallback() {
    this.render();
  }

  render() {
    if (this.shadowRoot) {
      const parsed = marked(this._content.trim()) as string;

      // Sanitize the parsed content
      const content = DOMPurify.sanitize(parsed, {
        FORBID_ATTR: ["target"], // Prevent adding target attribute directly
      });

      // Create a temporary element to manipulate links
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = content;

      // Find all anchor tags and set target="_blank"
      const links = tempDiv.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer"); // Recommended for security
      });

      this.shadowRoot.innerHTML = `<style>
        .markdown-content {
          word-break: break-word;
          white-space: pre-wrap;
          line-height: 1;
        }
        .markdown-content * {
          margin: unset;
          line-height: 1.5;
          border: none;
        }
        a {
          color: gray;
          text-decoration: none;
        }
      </style><div class="markdown-content">${tempDiv.innerHTML}</div>`;
    }
  }
}

customElements.define("elm-bark", ElmBark);
