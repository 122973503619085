"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestProvider = void 0;
var errors_1 = require("./errors");
function requestProvider(_) {
    if (_ === void 0) { _ = {}; }
    return new Promise(function (resolve, reject) {
        if (typeof window === 'undefined') {
            return reject(new Error('Must be called in a browser context'));
        }
        var webln = window.webln;
        if (!webln) {
            return reject(new errors_1.MissingProviderError('Your browser has no WebLN provider'));
        }
        webln.enable()
            .then(function () { return resolve(webln); })
            .catch(function (err) { return reject(err); });
    });
}
exports.requestProvider = requestProvider;
